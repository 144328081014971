import React, {useEffect, useRef, useState} from 'react';
import Header from '../../component/header/Header';
import {Row, Col, Form, Button,} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {Link, useHistory} from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Editor} from '@tinymce/tinymce-react';
import axios from 'axios';
import $ from "jquery";
import MaintainenceLogout from '../../../components/logout/MaintainenceLogout';
import SessionExpiredAdmin from '../../../components/SessionExpired/SessionExpiredAdmin'
import { makeStyles } from '@material-ui/core/styles';
import Back from '../../../assets/images/Back_white.png'
import './NotificationTemplates.css';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const NotificationTemplates = () => {
    const history = useHistory()
    const [deletebtnDisable, setDeletebtnDisable] = useState(false)
    const [notificationGet, setNotificationGet] = useState([])
    const [theHeader, setTheHeader] = useState('');
    const [theText, setTheText] = useState('')
    const [notificationRow, setNotificationRow] = useState(localStorage.getItem('notificationRowID'))
    const [statusNotification, setStatusNotification] = useState(0)
    const [fData, setFData] = useState(true)
    const [previewDisAble, setPreviewDisAble] = useState(false)
    const [notificationStatusActiveLS, setNotificationStatusActiveLS] = useState(localStorage.getItem("notificationStatusActive"))
    const [theHeaderNotificationEditor, setTheHeaderNotificationEditor] = useState(localStorage.getItem("notificationTheHeaderEditor"))
    const [theNotificationTextLS, setTheNotificationTextLS] = useState(localStorage.getItem("notificationTheText"))
    const [TokenExpired, setTokenExpired] = useState(false);


    //new
    const [nReturnID,setnReturnID] = useState('')

    // get Data
    const columns = [
        {
            dataField: 'statusid',
            text: 'Is Active',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.statusid !== null}
                    readOnly={true}
                />
            ),
            style: (cell, row) => {
                if (row.UserID === '') {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        },
        {
            dataField: 'theheader',
            text: 'Notification Type',
            formatter: (cell) => {
                if (cell) {
                    return (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cell
                            }}
                        />
                    );
                }
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === notificationRow) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        },
        {
            dataField: 'created',
            text: 'Created',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === notificationRow) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        },
        {
            dataField: 'updated',
            text: 'Updated',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === notificationRow) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }
    ];

    function getNotificationTemplates() {
        axios.get(`${process.env.REACT_APP_SERVICE_PLAN_API}user/maintnotificationsGet?nWhichScreen=0`)
            .then(function (response) {
                if (response.data.status) {
                    setTokenExpired(true)
                }else {
                    setNotificationGet(response.data)
                }
            })
    }

    useEffect(() => {
        getNotificationTemplates()
    }, [])
    // localStorage
    useEffect(() => {
        if (notificationStatusActiveLS && theHeaderNotificationEditor && theNotificationTextLS) {
            setStatusNotification(notificationStatusActiveLS)
            setTheHeader(theHeaderNotificationEditor);
            setTheText(theNotificationTextLS);

            if (notificationStatusActiveLS == 1) {
                $(`#formBasicCheckbox`).prop("checked", true)
                setStatusNotification(notificationStatusActiveLS)
            }
        }
    }, [])


    // edirot header
    const handleEditorChange = (value, editor) => {
        setTheHeader(value)
    }
    // editor theText Body
    const handleEditorChangetheText = (value, editor) => {
        setTheText(value)
    }

    // Table Row Selected
    const rowEvents = {
        onClick: (e, row) => {
            getNotificationTemplates()
            setPreviewDisAble(true)
            setTheHeader(row.theheader)
            setTheText(row.thetext)
            setnReturnID(row.nReturnID)
            setNotificationRow(row.id)
            setDeletebtnDisable(true)
            localStorage.setItem('editNotificationBtn', true)
            if (row.statusid == 1) {
                $(`#formBasicCheckbox`).prop("checked", true)
                setStatusNotification(row.statusid)
            } else {
                $(`#formBasicCheckbox`).prop("checked", false)
                setStatusNotification(row.statusid)
            }
        }
    }
    // checkbox
    const checkboxChanger = (e) => {
        let target = e.target;
        if (target.checked) {
            setStatusNotification(1)
        } else {
            setStatusNotification(0)
            localStorage.setItem('notificationStatusActive', 0)
        }
    }

    // Preview
    function hnadlerPreviewbtn() {
        localStorage.setItem('notificationTheHeaderEditor', theHeader);
        localStorage.setItem('notificationTheText', theText);
        localStorage.setItem('notificationStatusActive', statusNotification);
        localStorage.setItem("notificationRowID", notificationRow);
    }

    // Add New Btn
    const newButtonClick = () => {
        setPreviewDisAble(false)
        setTheHeader('')
        setTheText('')
        setnReturnID('')
        setStatusNotification(1)
        setDeletebtnDisable(false)
        setFData(true)

        if (document.getElementById('demo_ifr').focus()) {
            setPreviewDisAble(false)
        }
        document.getElementById('demo_ifr').focus()
        $(`#formBasicCheckbox`).prop("checked", true)
        localStorage.setItem('PreviewDisAble', false)
        localStorage.setItem('notificationTheHeaderEditor', '')
        localStorage.setItem('notificationTheText', '')
        localStorage.setItem('notificationStatusActive', 1)
        localStorage.setItem('editNotificationBtn', false)
        localStorage.setItem("notificationRowID", '')
    }
    // delete

    const deleteButtonClicked = () => {
        const data = {
            nID: notificationRow,
        };
        var token = localStorage.getItem('maintenance-token');
        axios.post(process.env.REACT_APP_SERVICE_PLAN_API + 'user/maintnotificationsDelete', data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then(res => {
            if (res.data.status) {
                setTokenExpired(true)
            }else {
                setTheHeader('')
                setTheText('')
                setnReturnID('')
                $(`#formBasicCheckbox`).prop("checked", false)
                document.getElementById('demo_ifr').focus()
                localStorage.setItem("notificationStatusActive", '')
                localStorage.setItem("notificationTheHeaderEditor", '')
                localStorage.setItem("notificationTheText", '')
                localStorage.setItem("notificationRowID", '')
                localStorage.setItem('editNotificationBtn', false)
                getNotificationTemplates()
            }
        }).catch(err => {
            console.log(err);

        });


    }
    // submit
    const termsSubmitHandler = (e) => {
        e.preventDefault()
        let editBtn = localStorage.getItem('editNotificationBtn')
        if (editBtn == "false") {
            const data = {
                nID: '',
                sHeader: theHeader,
                sText: theText,
                nIsActive: statusNotification,
                nReturnID:nReturnID
            }
            var token = localStorage.getItem('maintenance-token');
            axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}user/maintnotificationsSave`, data,
                {headers: {Authorization: `Bearer ${token}`}}
            ).then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                }else {
                    setDeletebtnDisable(false)
                    setTheHeader('')
                    setTheText('')
                    setnReturnID('')
                    $(`#formBasicCheckbox`).prop("checked", false)
                    localStorage.setItem("notificationStatusActive", '')
                    localStorage.setItem("notificationTheHeaderEditor", '')
                    localStorage.setItem("notificationTheText", '')
                    localStorage.setItem("notificationRowID", '')
                    localStorage.setItem('editNotificationBtn', false)
                    getNotificationTemplates()
                }
            }).catch(err => {
                console.log(err);

            });
        } else {
            const data = {
                nID: notificationRow == '' ? localStorage.getItem("notificationRowID") : notificationRow,
                sHeader: theHeader,
                sText: theText,
                nIsActive: statusNotification,
                nReturnID:nReturnID
            }
            var token = localStorage.getItem('maintenance-token');
            axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}user/maintnotificationsSave`, data,
                {headers: {Authorization: `Bearer ${token}`}}
            ).then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                }else {
                    setDeletebtnDisable(false)
                    setTheHeader('')
                    setTheText('')
                    setnReturnID('')
                    $(`#formBasicCheckbox`).prop("checked", false)
                    localStorage.setItem("notificationStatusActive", '')
                    localStorage.setItem("notificationTheHeaderEditor", '')
                    localStorage.setItem("notificationTheText", '')
                    localStorage.setItem("notificationRowID", '')
                    localStorage.setItem('editNotificationBtn', false)
                    getNotificationTemplates()
                }
            }).catch(err => {
                console.log(err);

            });
        }
        getNotificationTemplates()
    }

    return (
        <>
            {
                TokenExpired
                && <SessionExpiredAdmin/>
            }

            <section className="faq-main-section terms-of-use-main-section common-font-section">
                <Header title="Notification Templates"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maintenance" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <MaintainenceLogout/>
                    </Col>
                </Row>
                <div className="notification-template-inner-content">
                    <div className="divider-mobile"></div>
                    <Row className="mx-auto text-center pt-4 mobile-direction terms-of-use-table inner-table-form">
                        <Col lg={5} md={12} className="p-2 right-side-table " id="style-2">
                            <BootstrapTable keyField='id' rowClasses="custom-row-class0" hover data={notificationGet}
                                            columns={columns} rowEvents={rowEvents}/>
                        </Col>
                        <div className="divider-mobile"></div>
                        <Col lg={7} md={12} className="p-2">
                            <div className="Service-plan-addsection">

                                <Form onSubmit={(e) => termsSubmitHandler(e)} className="form-faq-section ">
                                    <Form.Group controlId="formBasicCheckbox"
                                                className="from-group-section active-status">
                                        <Form.Label>Status:</Form.Label>
                                        <Form.Check type="checkbox" label="Is Active" className="Checked-box"
                                                    onChange={e => checkboxChanger(e)}/>
                                    </Form.Group>
                                    <Form.Group className=" editor-data-new">
                                        <div className="terms-of-use-add-delet-btn">
                                            <Form.Label>Title:</Form.Label>
                                            <div className="add-button-section">
                                                <Button className="btn btn-success" onClick={newButtonClick}>Add New
                                                    Item</Button>
                                                {
                                                    deletebtnDisable ?
                                                        <Button variant="danger" onClick={() => {
                                                            const confirmBox = window.confirm(
                                                                "Are you sure you want to delete the selected record?"
                                                            )
                                                            if (confirmBox === true) {
                                                                deleteButtonClicked()
                                                            }
                                                        }}>Delete</Button>
                                                        :
                                                        <Button className="btn btn-danger disable-btn-delete"
                                                                disabled>Delete</Button>
                                                }
                                            </div>
                                        </div>
                                        <Editor apiKey='wcyyg5pauvw61talxv8gxwm0xefa0qhk0ii8erfnr7x37rnv'
                                                value={theHeader}
                                                id="demo"
                                                init={{
                                                    plugins: " fullpage  fullscreen  link advlist lists  ",
                                                    toolbar: " bold italic underline strikethrough | fontselect | fontsizeselect | formatselect | lineheight|alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                                }}
                                                onEditorChange={handleEditorChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="notification-preview-editor-sec">
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <Form.Label className="m-0 ">Body:</Form.Label>

                                          {/*  {
                                                previewDisAble ?
                                                    <Form.Label><Link to="/privacy-policy-preview"
                                                                      onClick={() => hnadlerPreviewbtn()}
                                                                      className="btn float-right preview-btn btn-danger  m-0">Preview</Link></Form.Label>
                                                    :
                                                    <Button className="btn btn-danger disable-btn-delete preview"
                                                            disabled>Preview</Button>
                                            }*/}
                                        </div>
                                        <Editor
                                            id='notification-msg-body'
                                            apiKey='wcyyg5pauvw61talxv8gxwm0xefa0qhk0ii8erfnr7x37rnv'
                                                value={theText}
                                                init={{
                                                    height: 430,
                                                    menubar: false,
                                                    plugins: "  fullpage  fullscreen  link advlist lists  ",
                                                    toolbar: " bold italic underline strikethrough | fontselect | fontsizeselect | formatselect | lineheight|alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                                }}
                                                onEditorChange={handleEditorChangetheText}
                                        />
                                    </Form.Group>
                                    <Button className="submite-button " type="submit" style={{height:'45px'}}>
                                        Save
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}
export default NotificationTemplates
