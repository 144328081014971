import React, {useEffect, useRef, useState} from 'react';
import Header from '../../../admin/component/header/Header';
import {Row, Col, Form, Button,} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {Link, useHistory} from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './faq.css';
import {Editor} from '@tinymce/tinymce-react';
import axios from 'axios';
import $ from "jquery";
import MaintainenceLogout from '../../../components/logout/MaintainenceLogout';
import SessionExpiredAdmin from '../../../components/SessionExpired/SessionExpiredAdmin'
import Back from '../../../assets/images/Back_white.png'


const FAQ = () => {
    const history = useHistory()
    const [faqID, setFAQID] = useState(localStorage.getItem('FaqId'))
    const [faqGet, setFaqGet] = useState([])
    const [deletebtnDisable, setDeletebtnDisable] = useState(false)
    const [thequestion, setThequestion] = useState('');
    const [theAnswers, setTheAnswers] = useState('')
    const editorReference = useRef();
    const [statusFAQ, setStatusFAQ] = useState(0)
    const [previewDisAble, setPreviewDisAble] = useState(false)
    const [TokenExpired, setTokenExpired] = useState(false);

    const columns = [
        {
            dataField: 'thequestion',
            text: 'Questions',
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === faqID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            }, formatter: (cell) => {
                if (cell) {
                    return (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cell
                            }}
                        />
                    );
                }
            },
            sort: true
        }, {
            dataField: 'statusid',
            text: 'Status',
            formatter: (cell) => {
                if (cell == "1") {
                    return (
                        <span>
                            <strong style={{color: '#28a745'}}> Active </strong>
                        </span>
                    );
                } else {
                    return (
                        <span>
                            <strong style={{color: '#dc3545'}}> In Active </strong>
                        </span>
                    );
                }
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === faqID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }, {
            dataField: 'created',
            text: 'Created',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },
            sortValue: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell).getTime();
                }
                return dateObj;
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === faqID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true

        }, {
            dataField: 'updated',
            text: 'Updated',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },
            sortValue: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell).getTime();
                }
                return dateObj;
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === faqID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }
    ];

    function getFaqQuestion() {
        axios.get(`${process.env.REACT_APP_SERVICE_PLAN_API}faq`)
            .then(function (response) {
                setFaqGet(response.data)
            })
    }

    useEffect(() => {
        getFaqQuestion()
    }, [])

    // localStorage
    let FAQthequestion = localStorage.getItem('thequestion')
    let FAQtheanswer = localStorage.getItem('theanswer')
    let FAQIsActive = localStorage.getItem('FAQActive')
    useEffect(() => {
        if (FAQthequestion) {
            setThequestion(FAQthequestion)
            setTheAnswers(FAQtheanswer)
            if (FAQIsActive == 1) {
                $(`#formBasicCheckbox`).prop("checked", true)
                setStatusFAQ(FAQIsActive)
            }
        }
    }, [])
    // edirot Question
    const handleEditorChange = (value, editor) => {
        setThequestion(value)
    }
    // editor Answers
    const handleEditorChangeAnswers = (value, editor) => {
        setTheAnswers(value)
    }
    // Table Row Selected
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            setPreviewDisAble(true)
            setFAQID(row.id)
            setThequestion(row.thequestion)
            setTheAnswers(row.theanswer)
            setDeletebtnDisable(true)
            localStorage.setItem('editbtnFAq', true)
            if (row.statusid == 1) {
                $(`#formBasicCheckbox`).prop("checked", true)
                setStatusFAQ(row.statusid)
            } else {
                $(`#formBasicCheckbox`).prop("checked", false)
                setStatusFAQ(row.statusid)
            }
        }
    }
    // checkbox
    const checkboxChanger = (e) => {
        let target = e.target;
        if (target.checked) {
            setStatusFAQ(1)
        } else {
            setStatusFAQ(0)
            localStorage.setItem('FAQActive', 0)
        }
    }
    // Preview
    const hnadlerPreviewbtn = () => {
        localStorage.setItem('thequestion', thequestion)
        localStorage.setItem('theanswer', theAnswers)
        localStorage.setItem('FAQActive', statusFAQ)
        localStorage.setItem('FaqId', faqID)
    }
    // Add New Btn
    const newButtonClick = () => {
        setPreviewDisAble(false)
        setThequestion('')
        setTheAnswers('')
        setStatusFAQ(1)
        setDeletebtnDisable(false)
        if (document.getElementById('demo_ifr').focus()) {
            setPreviewDisAble(false)
        }
        document.getElementById('demo_ifr').focus()
        $(`#formBasicCheckbox`).prop("checked", true)
        localStorage.setItem('thequestion', '')
        localStorage.setItem('theanswer', '')
        localStorage.setItem('FAQActive', 1)
        localStorage.setItem('editbtnFAq', false)
        localStorage.setItem('FaqId', '')
    }
    // delete
    const deleteButtonClicked = () => {
        setDeletebtnDisable(false)
        setThequestion('')
        setTheAnswers('')
        setStatusFAQ(0)
        document.getElementById('demo_ifr').focus()
        $(`#formBasicCheckbox`).prop("checked", false)
        localStorage.setItem('thequestion', '')
        localStorage.setItem('theanswer', '')
        localStorage.setItem('FAQActive', 0)
        localStorage.setItem('editbtnFAq', false)
        localStorage.setItem('FaqId', '')
        getFaqQuestion()
    }
    // submit
    const FAQSubmitHandler = (e) => {
        e.preventDefault()
        let editbtnFAq = localStorage.getItem('editbtnFAq')
        if (editbtnFAq == 'false') {
            const data = {
                nID: 0,
                sQuestion: thequestion,
                sAnswer: theAnswers,
                nSortOrder: 1,
                nIsActive: statusFAQ
            }
            var token = localStorage.getItem('maintenance-token');
            axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}page/faqsave`, data,
                {headers: {Authorization: `Bearer ${token}`}}
            ).then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                } else {
                    setDeletebtnDisable(false)
                    setThequestion('')
                    setTheAnswers('')
                    $(`#formBasicCheckbox`).prop("checked", false)
                    localStorage.setItem('thequestion', '')
                    localStorage.setItem('theanswer', '')
                    localStorage.setItem('FAQActive', 0)
                    localStorage.setItem('editbtnFAq', false)
                    localStorage.setItem('FaqId', '')
                    getFaqQuestion()
                }
            }).catch(err => {
                console.log(err);

            });

        } else {
            const data = {
                nID: faqID == '' ? localStorage.getItem("FaqId") : faqID,
                sQuestion: thequestion,
                sAnswer: theAnswers,
                nSortOrder: 1,
                nIsActive: statusFAQ
            }
            var token = localStorage.getItem('maintenance-token');
            axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}page/faqsave`, data,
                {headers: {Authorization: `Bearer ${token}`}}
            ).then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                }else {
                    getFaqQuestion()
                }
            }).catch(err => {
                console.log(err);

            });
        }
        getFaqQuestion()
    }

    return (
        <>
            {
                TokenExpired
                && <SessionExpiredAdmin/>
            }
            <section className="faq-main-section common-font-section">
                <Header title="Frequently Asked Questions"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maintenance" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <MaintainenceLogout/>
                    </Col>
                </Row>
                <div className="faq-inner-content">
                    <div className="divider-mobile"></div>
                    <div className="FAQ-inner-Content-sec">
                        <Row className="mx-auto text-center pt-4 mobile-direction inner-table-form">
                            <Col lg={6} md={12} className="p-2 right-side-table " id="style-2">
                                <BootstrapTable keyField='id' rowClasses="custom-row-class0" hover data={faqGet}
                                                columns={columns} rowEvents={rowEvents}/>
                            </Col>
                            <div className="divider-mobile"></div>
                            <Col lg={6} md={12} className="p-2">
                                <div className="Service-plan-addsection">
                                    <div className="add-button-section">
                                        <Button className="btn btn-success" onClick={newButtonClick}>Add New
                                            Item</Button>
                                        {
                                            deletebtnDisable ?
                                                <Button variant="danger" onClick={() => {
                                                    const confirmBox = window.confirm(
                                                        "Are you sure you want to delete the selected record?"
                                                    )
                                                    if (confirmBox === true) {
                                                        deleteButtonClicked()
                                                    }
                                                }}>Delete</Button>
                                                :
                                                <Button className="btn btn-danger disable-btn-delete"
                                                        disabled>Delete</Button>
                                        }
                                    </div>
                                    <Form onSubmit={(e) => FAQSubmitHandler(e)} className="form-faq-section">
                                        <Form.Group controlId="formBasicCheckbox"
                                                    className="from-group-section active-status">
                                            <Form.Label>Status:</Form.Label>
                                            <Form.Check type="checkbox" label="Is Active" className="Checked-box"
                                                        onChange={e => checkboxChanger(e)}/>
                                        </Form.Group>
                                        <Form.Group className="editor-data-new mb-4">
                                            <div className="terms-of-use-add-delet-btn">
                                                <Form.Label>Question:</Form.Label>

                                            </div>
                                            <Editor apiKey='97one8fo56cr08pc620j9rhz2pku2c3kvkxfg3xrs0n8absc'
                                                    value={thequestion}
                                                    id="demo"
                                                    init={{
                                                        plugins: "  fullpage  fullscreen  link advlist lists  ",
                                                        toolbar: " bold italic underline strikethrough | fontselect | fontsizeselect | formatselect | lineheight|alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                                        auto_focus: true,
                                                        content_style: "body p { line-height: 1.5; }",
                                                    }}
                                                    onEditorChange={handleEditorChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="preview-editor-sec-enw-faq">
                                            <div className="d-flex justify-content-between align-items-baseline">
                                                <Form.Label className="m-0 ">Answers:</Form.Label>
                                                {
                                                    previewDisAble ?
                                                        <Form.Label><Link to="/faq-preview"
                                                                          onClick={() => hnadlerPreviewbtn()}
                                                                          className="btn float-right preview-btn btn-danger  m-0">Preview</Link></Form.Label>
                                                        :
                                                        <Button className="btn btn-danger disable-btn-delete preview"
                                                                disabled>Preview</Button>
                                                }

                                                {/* <Form.Label><Link to="/faq-preview"
                                                onClick={() => hnadlerPreviewbtn()} className="btn float-right preview-btn btn-danger  m-0">Preview</Link></Form.Label> */}
                                            </div>
                                            <Editor apiKey='97one8fo56cr08pc620j9rhz2pku2c3kvkxfg3xrs0n8absc'
                                                    value={theAnswers}
                                                    init={{
                                                        plugins: "  fullpage  fullscreen  link advlist lists  ",
                                                        toolbar: " bold italic underline strikethrough | fontselect | fontsizeselect | formatselect | lineheight|alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                                        content_style: "body p { line-height: 1.5; }",
                                                    }}
                                                    onEditorChange={handleEditorChangeAnswers}
                                            />
                                        </Form.Group>
                                        <Button className="submite-button " type="submit">
                                            Save
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </section>
        </>
    )
}

export default FAQ


