import React, {useState, useEffect, useRef} from 'react';
import Header from '../../../admin/component/header/Header';
import {Row, Col, Form, Button, Modal,} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {Link} from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './user.css';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import $ from "jquery";
import MaintainenceLogout from '../../../components/logout/MaintainenceLogout';
import {makeStyles} from '@material-ui/core/styles';
import SessionExpiredAdmin from '../../../components/SessionExpired/SessionExpiredAdmin'
import Back from '../../../assets/images/Back_white.png'
import UserInformationModel from "../../../components/UserInformationModel";
import CommunicationModel from "../../../components/CommunicationModel";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
const UserInformation = () => {
    const [contactdata, setContactdata] = useState([]);
    const [billingData, setBillingData] = useState([]);
    const [nState, setNState] = useState();
    const [accountNumber, setAccountNumber] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email1, setEmail1] = useState('');
    const [phone1, setPhone1] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');
    const [logingData, setLogingData] = useState([]);
    const [userListData, setUserListData] = useState([]);
    const [notes, setNotes] = useState('');
    const [userId, setUserId] = useState('');
    const [TokenExpired, setTokenExpired] = useState(false);

    const [contactDetailModel, setContactDetail] = useState(false);
    const [servicePlanModel, setServicePlanModel] = useState(false);
    const [loginDetailModel, setLoginDetailModel] = useState(false);
    const [communicationModel, setCommunicationModel] = useState(false);
    const [checkinDates, setcheckinDates] = useState(false);
    const [isActiveOnly, setIsActiveOnly] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [checkinData, setCheckinData] = useState([]);
    const [servicePlan, setServicePlan] = useState('');
    const [activeated, setActiveated] = useState('');
    const [LastCheckIn, setLastCheckIn] = useState('');
    const [NextCheckIn, setNextCheckIn] = useState('');
    const [CommunicationDetails, setCommunicationData] = useState([]);

    const handleContactDetail = () => {
        setContactDetail(!contactDetailModel)
        if (!contactDetailModel) {
            GetContactData();
        }
    }
    const handleServicePlanModel = () => {
        setServicePlanModel(!servicePlanModel)
        if (!servicePlanModel) {
            axios.get(`user/boServicePlansGet?nUserID=${userId}`)
                .then((res) => {
                    setBillingData(res.data)
                }).catch((err) => {
                console.log(err)
            })
        }
    }

    const handleLoginDetailModel = () => {
        setLoginDetailModel(!loginDetailModel)
        if (!loginDetailModel) {
            const data  = {nUserID:userId}
            axios.post('user/getlogins', data)
                .then(res => {
                    setLogingData(res.data)
                }).catch(err => {
                console.log(err);
            });
        }
    }
    const getCommunicationData = () => {
        axios.get(`user/boCommunicationsGet?nUserID=${userId}`)
            .then((res) => {
                setCommunicationData(res.data)
            }).catch((err) => {
            console.log(err)
        })
    }

    const handlecommunicationModel = () => {
        setCommunicationModel(!communicationModel)
        if (!checkinDates) {
            getCommunicationData();
        }
    }
    const handlecheckinDatesModel = () => {
        setcheckinDates(!checkinDates)
        if (!checkinDates) {
            axios.get(`user/futurecheckindates?nUserID=${userId}`)
                .then((res) => {
                    setCheckinData(res.data.map(d => d['DATE_FORMAT(checkindate, "%m-%d-%Y")']));
                }).catch((err) => {
                console.log(err)
            })
        }

    }

    const [open, setOpen] = React.useState(false);
    const classes = useStyles();


    const handleClose = () => {
        setOpen(false);
    };

    // table
    const columns = [
        {
            dataField: 'accountnumber',
            text: 'Account #',
            style: (cell, row) => {
                if (row.UserID === userId) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle:()=>{
                    return {'width':'15%'}
            },
            sort: true
        },
        {
            dataField: 'fname',
            text: 'First Name',
            style: (cell, row) => {
                if (row.UserID === userId) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }, {
            dataField: 'lname',
            text: 'Last Name',
            style: (cell, row) => {
                if (row.UserID === userId) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        },
        {
            dataField: 'phone',
            text: 'Phone',
            style: (cell, row) => {
                if (row.UserID === userId) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true

        },
        {
            dataField: 'email',
            text: 'Email',
            style: (cell, row) => {
                if (row.UserID === userId) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        },
        {
            dataField: 'isActive',
            text: 'Is Active',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.isActive !== null}
                    readOnly={true}
                />
            ),
            style: (cell, row) => {
                if (row.UserID === userId) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }
    ];

    // login History data
    const columnsLogin = [
        {
            dataField: 'logindate',
            text: 'Login Date',
            headerStyle:()=>{
               return { 'text-align':'center'}
            },
            sort: true
        }, {
            dataField: 'logOutDate',
            text: 'Logout Date/Date',
            headerStyle:()=>{
                return { 'text-align':'center'}
            },
            sort: false
        }, {
            dataField: 'ipAddress',
            text: 'IpAddress',
            headerStyle:()=>{
                return { 'text-align':'center'}
            },
            sort: true
        }
    ];

    // Payment History
    const columnsPayment = [
        {
            dataField: 'datereceived',
            text: 'Paymnet Date',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },

            sort: true
        }, {
            dataField: 'paymentnumber',
            text: 'credit Card #',
            sort: true
        }, {
            dataField: 'type',
            text: 'type',
            sort: true
        },
        {
            dataField: 'amount',
            text: 'Amount',
            formatter: (cell) => {
                let dateObj = cell.trim();
                return `$ ${dateObj}`;
            },
            sortValue: (cell) => {
                let cellFormat = cell.trim();
                cellFormat = cellFormat.replace('$', '')
                return cellFormat;
            },
            sort: true
        }
    ];
    // billing
    const columnsBilling = [
        {
            dataField: 'accountnumber',
            text: 'Account #',
            sort: false,
            headerStyle:()=>{
              return {'vertical-align':'middle','padding':'0','width':"10%"}
            },
        },
        {
            dataField: 'fname',
            text: 'First Name',
            sort: false,
            headerStyle:()=>{
              return {'vertical-align':'middle','padding':'0','width':"12%"}
            },
        }, {
            dataField: 'lname',
            text: 'Last Name',
            sort: false,
            headerStyle:()=>{
              return {'vertical-align':'middle','padding':'0','width':"12%"}
            },
        },
        {
            dataField: 'name',
            text: 'Service Plan',
            sort: false,
            headerStyle:()=>{
              return {'vertical-align':'middle','padding':'0','width':'32%'}
            },
        },
        {
            dataField: 'subscription',
            text: 'Subscription',
            sort: false,
            headerStyle:()=>{
              return {'vertical-align':'middle','text-align':'center','width':"9%",'padding':'0'}
            },
            align:'center'
        },
        {
            dataField: 'DateOfSubscription',
            text: 'Date of Subscription',
            sort: false,
            headerStyle:()=>{
              return {'vertical-align':'middle','text-align':'center','width':"9%",'padding':'0'}
            },
            formatter: (value,row) => {
                return (<span>{row.DateOfSubscription && moment(row.DateOfSubscription).format('MM/DD/YYYY')}</span>);
            },
            align:'center'
        },
        {
            dataField: 'amount',
            text: 'Amount',
            sort: false,
            headerStyle:()=>{
              return {'vertical-align':'middle','text-align':'center','width':'8%'}
            },
            align:'center'
        },
        {
            dataField: 'Status',
            text: 'Status',
            sort: false,
            headerStyle:()=>{
                return {'vertical-align':'middle','text-align':'center','width':'7%'}
            },
            align:'center'
        }
    ]
    const columnsCommunication = [
        {
            dataField: 'created',
            text: 'Date/Time Of Call',
            style:()=>{
                return {textAlign:'left'}
            },
            formatter: (value, row) => (
                <span>{moment(row.created).format('MM/DD/YYYY hh:mm:ssA')}</span>
            ),
            headerStyle:()=>{
                return {'width':'20%'}
            },
            sort: true
        },
        {
            dataField: 'updated',
            text: 'Updated',
            style:()=>{
                return {textAlign:'left'}
            },
            formatter: (value, row) => (
                <span>{moment(row.updated).format('MM/DD/YYYY hh:mm:ssA')}</span>
            ),
            headerStyle:()=>{
                return {'width':'20%'}
            },
            sort: false
        }, {
            dataField: 'message',
            text: 'Conversation',
            formatter: (value, row) => (
                <span>{row.message}</span>
            ),
            style:()=>{
                return {textAlign:'left',whiteSpace: 'break-spaces'}
            },
            sort: true
        },
    ];

    function GetContactData() {
        const data = {
            nUserID: userId
        };
        var token = localStorage.getItem('maintenance-token');
        axios.post(process.env.REACT_APP_SERVER + 'contact/getall', data,
            {headers: {Authorization: `Bearer ${token}`}})
            .then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                } else {
                    setContactdata(res.data && res.data);
                }
            }).catch(err => {
        });

    }

    const columnsContact = [
        {
            dataField: 'fname',
            text: 'First Name',
            sort: true
        }, {
            dataField: 'lname',
            text: 'Last Name',
            sort: true
        },
        {
            dataField: 'phonenumber',
            text: 'Phone',
            sort: true
        },
        {
            dataField: 'isICE',
            text: 'ICE',
            formatter: (cellContent, row) => (
                <div className="checkbox">
                    <input type="checkbox" name={row.contactid} checked={row.isICE == 1} className="tablecheckbox"
                           readOnly/>
                </div>
            ),
            sort: false,
        },
        {
            dataField: 'orderofpriority',
            text: 'Order',
            sort: true
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true
        }
    ]

    useEffect(() => {
        if (contactdata.length > 0) {
            contactdata && contactdata.forEach(els => {
                if (els.isICE == 1) {
                    $(`.tablecheckbox[name=${els.contactid}]`).prop("checked", true)
                }
            });
        }

    });

    // Table Row Selected
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            const cdata = userListData.find(ele => ele == row);
            setUserId(cdata.UserID);
            setAccountNumber(cdata.accountnumber == null ? '' : cdata.accountnumber);
            setFname(cdata.fname == null ? '' : cdata.fname);
            setLname(cdata.lname == null ? '' : cdata.lname);
            setEmail1(cdata.email == null ? '' : cdata.email);
            setPhone1(cdata.phone == null ? '' : cdata.phone);
            setCity(cdata.city == null ? '' : cdata.city);
            setNState(cdata.State == null ? '' : cdata.State);
            setPostalCode(cdata.zipcode == null ? '' : cdata.zipcode);
            setNotes(cdata.notes == null ? '' : cdata.notes);
            setAddress(cdata.address == null ? '' : cdata.address);
            setActiveated(cdata.DateOfActivation == null ? '' : moment(cdata.DateOfActivation).format('MM/DD/YYYY hh:mmA'));
            setServicePlan(cdata.ServicePlan == null ? '' : cdata.ServicePlan);
            setLastCheckIn(cdata.lastcheckindate == null ? '' : moment(cdata.lastcheckindate).format('MM/DD/YYYY hh:mmA'));
            setNextCheckIn(cdata.nextcheckindate == null ? '' : moment(cdata.nextcheckindate).format('MM/DD/YYYY'));
        }
    }

    // user list
    useEffect(() => {
        getAllUsers();
    }, [])

    const getAllUsers = () => {
        const data = {
            nUserID: userId,
            sSearch: searchValue,
            isActiveOnly: isActiveOnly ? 1 : 0
        };
        axios.post('user/getallusers', data)
            .then(res => {
                setUserListData(res.data)
            }).catch(err => {
            console.log(err)
        });
    }
    return (
        <>
            {TokenExpired && <SessionExpiredAdmin/>}
            <section className="user-information-section common-font-section">
                <Header title="User Information"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maintenance" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="btnall">
                        <MaintainenceLogout/>
                    </Col>
                </Row>
                <section className="inner-user-info-section">
                    <div className="divider-mobile"/>
                    <Row className=" text-center pt-2  inner-table-form user-info-row">
                        <Col lg="11" className={'p-0'}>
                            <Row className="mb-2">
                                <Col lg="8" md="12">
                                    <Row className={'justify-content-between'}>
                                        <h6 className="title-userinfo-compo">User List</h6>
                                    </Row>
                                    <div className={'countRowData'}>
                                        <div className={'d-flex align-items-center '}>
                                            <Form.Check
                                                className="mr-3"
                                                type={'checkbox'}
                                                label={'Is Active Only'}
                                                id={`default`}
                                                onChange={(e) => setIsActiveOnly(e.target.checked)}
                                            />
                                            <Form.Group className="m-0 mr-1">
                                                <Form.Control
                                                    placeholder="Search: Account No/Email/Name"
                                                    type="text"
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                    className="search-field"
                                                />
                                            </Form.Group>
                                            <button className="btn custom-link btn-primary w-auto mb-0"
                                                    onClick={getAllUsers}>
                                                Search
                                            </button>
                                        </div>
                                        <div className={'d-flex align-items-center '}>
                                            <Form.Label>Count:</Form.Label>
                                            <Form.Group as={Row} className={'countController'}>
                                                <Form.Control type="text" readOnly={true} value={userListData.length}/>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="left-side-user-content">
                                        <div className="user-list-table-left-side" id="style-2"
                                             style={{maxHeight: '803px'}}>
                                            <BootstrapTable
                                                keyField='id'
                                                hover
                                                data={userListData.length > 0 ? userListData : ''}
                                                columns={columns}
                                                rowEvents={rowEvents}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="4" md="12" className="user-data-form-section">
                                    <div className="divider-mobile-data "/>
                                    <h6 className="title-userinfo-compo">User Details</h6>
                                    <section className="user-form-section">
                                        <Form className="form-user-information">
                                            <Form.Group as={Row} controlId="formBasicLastName">
                                                <Col lg="3">
                                                    <Form.Label>Account #:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={accountNumber}
                                                                  onChange={(e) => setAccountNumber(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formBasicFirstName">
                                                <Col lg="3">
                                                    <Form.Label>Service Plan:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={servicePlan} placeholder=""
                                                                  onChange={(e) => setServicePlan(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formBasicFirstName">
                                                <Col lg="3">
                                                    <Form.Label>Activated:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={activeated} placeholder=""
                                                                  onChange={(e) => setActiveated(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formBasicFirstName">
                                                <Col lg="3">
                                                    <Form.Label>Last:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={LastCheckIn} placeholder=""
                                                                  onChange={(e) => setLastCheckIn(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formBasicFirstName">
                                                <Col lg="3">
                                                    <Form.Label>Next:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={NextCheckIn} placeholder=""
                                                                  onChange={(e) => setNextCheckIn(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formBasicFirstName">
                                                <Col lg="3">
                                                    <Form.Label>First Name:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={fname} placeholder=""
                                                                  onChange={(e) => setFname(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formBasicLastName">
                                                <Col lg="3">
                                                    <Form.Label>Last Name:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={lname} placeholder=""
                                                                  onChange={(e) => setLname(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formBasicAddress">
                                                <Col lg="3">
                                                    <Form.Label>Address:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={address}
                                                                  onChange={(e) => setAddress(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Row className="city-pincode-section">
                                                <Col lg="8">
                                                    <Form.Group as={Row} controlId="formBasicCity">
                                                        <Col lg="4">
                                                            <Form.Label>City:</Form.Label>
                                                        </Col>
                                                        <Col lg="8" className="cityInput">
                                                            <Form.Control type="text" className="city-section"
                                                                          value={city}
                                                                          onChange={(e) => setCity(e.target.value)}/>
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="4">
                                                    <Form.Group as={Row} controlId="formBasicState">
                                                        <Col lg="4">
                                                            <Form.Label>State:</Form.Label>
                                                        </Col>
                                                        <Col lg="8" className="states-data">
                                                            <Form.Control value={nState} type="text"
                                                                          onChange={(e) => setNState(e.target.value)}/>
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Form.Group as={Row} controlId="formBasicPostalCode">
                                                <Col lg="3">
                                                    <Form.Label>Postal Code:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" className="sort-input-fieldA"
                                                                  value={postalCode}
                                                                  onChange={(e) => setPostalCode(e.target.value)}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formBasicPhone1">
                                                <Col lg="3">
                                                    <Form.Label>Phone:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={phone1}
                                                                  onChange={(e) => setPhone1(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formBasicEmail1">
                                                <Col lg="3">
                                                    <Form.Label>Email:</Form.Label>
                                                </Col>
                                                <Col lg="9">
                                                    <Form.Control type="text" value={email1}
                                                                  onChange={(e) => setEmail1(e.target.value)}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicNote">
                                                <Form.Label>Note:</Form.Label>
                                                <Form.Control as="textarea" rows={9} type="text" value={notes}
                                                              onChange={(e) => setNotes(e.target.value)}/>
                                            </Form.Group>
                                        </Form>
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                        <div className="divider-mobile mb-3"/>
                        <Col lg={1} className={'p-0 ActionsBtn'}>
                            <h6 className="title-userinfo-compo">Actions</h6>
                            <Row>
                                <Button className={'custom-link'} disabled={!userId} onClick={handleContactDetail}>Contacts</Button>
                            </Row>
                            <Row>
                                <Button className={'custom-link'} disabled={!userId} onClick={handleServicePlanModel}>Service Plan</Button>
                            </Row>
                            <Row>
                                <Button className={'custom-link'} disabled={!userId} onClick={handleLoginDetailModel}>Login History</Button>
                            </Row>
                            <Row>
                                <Button className={'custom-link'} disabled={!userId}
                                        onClick={handlecommunicationModel}>Communication</Button>
                            </Row>
                            <Row>
                                <Button className={'custom-link'} disabled={!userId} onClick={handlecheckinDatesModel}>CheckIn
                                    Dates</Button>
                            </Row>
                        </Col>
                    </Row>
                    <UserInformationModel
                        title={'contact details'}
                        tabDetailModel={contactDetailModel}
                        handleTabDetail={handleContactDetail}
                        size={'xl'}
                        data={contactdata}
                        column={columnsContact}
                        className={'contact-info-model-body'}
                    />
                    <UserInformationModel
                        title={'service plan details'}
                        tabDetailModel={servicePlanModel}
                        handleTabDetail={handleServicePlanModel}
                        size={'xl'}
                        data={billingData}
                        column={columnsBilling}
                        className={'service-info-table'}
                    />
                    <UserInformationModel
                        title={`${fname} ${lname}`}
                        tabDetailModel={loginDetailModel}
                        handleTabDetail={handleLoginDetailModel}
                        size={'lg'}
                        data={logingData}
                        column={columnsLogin}
                        className={'custom-info-model-body'}
                    />
                    <CommunicationModel
                        title={'Communications'}
                        tabDetailModel={communicationModel}
                        handleTabDetail={handlecommunicationModel}
                        size={'xl'}
                        data={CommunicationDetails}
                        column={columnsCommunication}
                        className={'communication-model'}
                        userId={userId}
                        getCommunicationData={getCommunicationData}
                    />
                    <Modal
                        centered
                        show={checkinDates}
                        onHide={handlecheckinDatesModel}
                        backdrop="static"
                        keyboard={false}
                        className="checkin-date-modal"
                    >
                        <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}} closeButton>
                            <Modal.Title>Check In Dates</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {checkinData.map((date) =>
                                <>
                                    <div className={"date-label"}>{date}</div>
                                    <hr/>
                                </>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handlecheckinDatesModel}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </section>
            </section>
        </>
    )
}

export default UserInformation
